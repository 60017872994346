import { Controller } from "@hotwired/stimulus"
import MapboxGeocoder from "@mapbox/mapbox-gl-geocoder"

export default class extends Controller {

  static values = {
    apiKey: String,
    markers: Array
  }

  connect() {
    console.log("Map succesfully loaded")

    mapboxgl.accessToken = this.apiKeyValue

    this.map = new mapboxgl.Map({
      container: this.element,
      style: "mapbox://styles/mapbox/streets-v10"
    });

    this.#addMarkersToMap()
    this.#fitMapToMarkers()
  }

    #addMarkersToMap() {
      this.markersValue.forEach((marker) => {
        new mapboxgl.Marker()
          .setLngLat([ marker.lng, marker.lat ])
          .addTo(this.map);

        // const customMarker = document.createElement("div")
        // customMarker.className = "marker"
        // customMarker.style.backgroundImage = `url('${marker.image_url}')`
        // customMarker.style.backgroundSize = "contain"
        // customMarker.style.width = "25px"
        // customMarker.style.height = "25px"

        // new mapboxgl.Marker(customMarker)
        // .setLngLat([marker.lng, marker.lat])
        // .setPopup(popup)
        // .addTo(this.map)
      })
    }

    #fitMapToMarkers() {
      const bounds = new mapboxgl.LngLatBounds()
      this.markersValue.forEach(marker => bounds.extend([ marker.lng, marker.lat ]))
      this.map.fitBounds(bounds, { padding: 70, maxZoom: 16, duration: 0 });
    }


}
